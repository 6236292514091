@import "fonts";
@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/flag-icon-css/sass/flag-icon";

body {
    background-image: url('/build/images/CCXX.jpg');
    background-position:bottom center;
    background-repeat:no-repeat;
}

div.container {
    background-color:transparent;
}
